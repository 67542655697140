export const environment = {
  production: false,
  // apiUrl: 'http://localhost:3000',
  apiUrl: 'https://tmdigital-api-stag.terramagna.com.br',
  mapboxToken:
    'pk.eyJ1IjoieWFucHMiLCJhIjoiY20ycDJjeHU1MG44azJrcTh0Z2k4ODJuNyJ9.Pz39eQ56inKFuVg7GmEZlw',
  ampKey: '556ba086d657687a5603c27a4ddaed17',
  ampSecret: 'b075f045d270d7ccd08d094cd5734365',
  tmPanel: 'https://tm-platform-dev--new-tm-digital-logout-gvtm9lky.web.app',
  loginURL: 'https://login-dev.terramagna.com.br',
};
